<template>
  <div class="register-wrap">
    <div class="container">
      <div class="lable">{{ $t('login.forgot') }}</div>
      <!-- 忘记密码 -->
      <div class="detail">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
          size="large"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 13 }"
          v-if="!success"
        >
        <!-- 手机号或邮箱 -->
          <a-form-item :label="$t('login.mobile_or_email')" name="mobile">
            <a-input
              v-model:value="formState.mobile"
              :disabled="disabled"
              :placeholder="$t('LB_QA_PleaseEnter')"
              size="large"
            />
            <!-- 请输入 -->
          </a-form-item>
          <!-- 验证码 -->
          <a-form-item :label="$t('login.code')" name="code">
            <a-input-search
              v-model:value="formState.code"
              size="large"
              :placeholder="$t('LB_QA_PleaseEnter')"
              @search="getCode(formState.mobile)"
            >
            <!-- 请输入 -->
              <template #enterButton>
                <a-button
                  type="primary"
                  style="background: #faad14; border-color: #faad14"
                  :disabled="disabled"
                  >{{ codeText }}</a-button
                >
              </template>
            </a-input-search>
          </a-form-item>
          <!-- 新密码 -->
          <a-form-item :label="$t('login.new_password')" name="newpass">
            <a-input-password
              v-model:value="formState.newpass"
              type="password"
              :placeholder="$t('LB_QA_PleaseEnter')"
              size="large"
            />
            <!-- 请输入 -->
          </a-form-item>
          <!-- 确认密码 -->
          <a-form-item :label="$t('login.confirm_password')" name="newpass2">
            <a-input-password
              v-model:value="formState.newpass2"
              type="password"
              :placeholder="$t('LB_QA_PleaseEnter')"
              size="large"
            />
            <!-- 请输入 -->
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 14, offset: 9 }">
            <span class="btn"
              ><a-button
                type="primary"
                block
                @click.prevent="onSubmit"
                size="large"
                >{{ $t('CM_Confirm') }}</a-button
              ></span
            >
            <!-- 确定 -->
            <span class="btn"
              ><a-button
                @click.prevent="toLogin"
                block
                size="large"
                style="margin-left: 20px"
                >{{ $t('login.to_login') }}</a-button
              ></span
            >
            <!-- 去登录 -->
          </a-form-item>
        </a-form>
        <!-- 成功找回密码！ -->
        <a-result
          status="success"
          :title="$t('login.successfully_retrieved_the_password')"
          :sub-title="$t('login.success_tip')"
          v-else
        >
        <!-- 您的密码已经重置，快去登录平台试试吧。 -->
          <template #extra>
            <a-button key="console" type="primary" @click="toLogin"
              >{{ $t('login.to_login') }}</a-button
            >
          </template>
          <!-- 去登录 -->
        </a-result>
      </div>
    </div>
    <div class="copyright">
      <span v-if="studyCopyright" style="padding: 0 5px">{{
        studyCopyright
      }}</span>
      <a
        :href="`https://beian.miit.gov.cn${
          newWindow == 1 ? '?ddtab=true' : ''
        }`"
        :target="newWindow == 1 ? '_blank' : '_self'"
        v-if="icpNo"
      >
        {{ icpNo }}
      </a>
      <a
        v-if="ncNo"
        :target="newWindow == 1 ? '_blank' : '_self'"
        :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${ncNo}${
          newWindow == 1 ? '&ddtab=true' : ''
        }`"
      >
        公网安备 {{ ncNo }}号
      </a>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, ref, watch, toRefs } from "vue";
import { useStore } from "vuex";
import { validatorMobileOrEmail, validatorPassword } from "@/utils/formRules";
import { sendcode, resetpass } from "@/api/user";
import { getCurrentInstance } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { appendJs } from "@/utils/tools.js";
import { currentHost } from "@/utils/request.js";

export default {
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      studyCopyright: "",
      icpNo: "",
      ncNo: "",
    });

    watch(
      () => store.getters.platformConfig,
      (val) => {
        state.studyCopyright = val.studyCopyright;
        state.icpNo = val.icpNo;
        state.ncNo = val.ncNo;
        appendJs("Track", val.studyTrack);
      },
      { immediate: true, deep: true }
    );

    const formRef = ref();
    const formState = reactive({
      mobile: "",
      code: "",
      newpass: "",
      newpass2: "",
    });
    const codeText = ref($t('login.get_code'));
    // 获取验证码
    const disabled = ref(false);
    const success = ref(false);

    let validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject($t('login.confirm_password_p'));
        // 请输入确认密码
      } else if (value !== formState.newpass) {
        return Promise.reject($t('login.not_match'));
        // 两次密码不一致
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      mobile: [
        {
          required: true,
          message: $t('login.mobile_or_email_p'),
          // 请输入手机号或邮箱
          trigger: "change",
        },
        {
          validator: validatorMobileOrEmail,
          trigger: "change",
        },
      ],
      code: [
        {
          required: true,
          message: $t('login.code_p'),
          // 请输入验证码
          trigger: "change",
        },
      ],
      newpass: [
        {
          required: true,
          message: $t('login.new_password_p'),
          // 请输入新密码
          trigger: "change",
        },
        {
          validator: validatorPassword,
          trigger: "change",
        },
      ],
      newpass2: [
        {
          required: true,
          validator: validatePass2,
          trigger: "change",
        },
      ],
    };

    const getCode = (searchValue) => {
      if (
        !/^1[3456789]\d{9}$/.test(searchValue) &&
        !/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(
          searchValue
        )
      ) {
        proxy.$message.error($t('login.mobile_or_email_err'));
        // 手机号或邮箱不正确
        return false;
      }
      sendcode({
        mobile: searchValue,
        site: currentHost,
      }).then((res) => {
        if (res.ret == 0) {
          proxy.$message.success(res.msg); //发送验证码成功，请查收！
          disabled.value = true;
          codeText.value = 60;
          let timer = setInterval(() => {
            codeText.value = parseInt(codeText.value) - 1;
            if (codeText.value == 0) {
              disabled.value = false;
              codeText.value = $t('login.get_code');
              // 获取验证码
              clearInterval(timer);
            }
          }, 1000);
        } else {
          proxy.$message || proxy.$message.error(res.msg || $t('login.failed_get_code'));
          // 获取验证码失败
        }
      });
    };

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          resetpass({
            account: formState.mobile,
            newpass: proxy.$getRsaCode(formState.newpass),
            code: formState.code,
            site: currentHost,
          }).then((res) => {
            if (res.ret == 0) {
              success.value = true;
            }
          });
        })
        .catch((error) => {});
    };

    const toLogin = () => {
      router.push({ path: `/login` });
    };

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      formRef,
      formState,
      codeText,
      disabled,
      rules,
      getCode,
      onSubmit,
      success,
      toLogin,
    };
  },
};
</script>

<style lang="less" scoped>
@import "../assets/style/register.less";
.register-wrap .container .detail {
  padding: 33px;
}
.copyright {
  width: 960px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  color: #899299;
  ::v-deep(a) {
    color: #899299 !important;
  }
}
</style>
